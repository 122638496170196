import React from 'react'
import Link from 'gatsby-link'
import color from 'color'

// Images
import logoBlack from '../images/ekko-app_company-logo_black.svg'
import logoWhite from '../images/ekko-app_company-logo_white.svg'

// Components
import Button from './button'
import FreeDemoModal from './freeDemoModal'

// Style
// import { css } from 'emotion'¨
import { css } from '@emotion/core'

import colors from '../styles/colors'
import typography from '../styles/typography'
import common from '../styles/common'

// Icons
import { MenuIcon, CloseIcon } from 'mdi-react'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: false,
      menuOpen: false,
      modalActive: false,
    }
  }

  checkScrollHeight = () => {
    let scrollHeight = window.pageYOffset
    if (scrollHeight > 30) {
      this.setState({
        scrolled: true,
      })
    } else {
      this.setState({
        scrolled: false,
      })
    }
  }

  componentDidMount() {
    // Toggles header-scrolled state
    window.addEventListener('scroll', this.checkScrollHeight)
  }

  render() {
    return (
      <div
        css={header}
        className={this.state.scrolled ? ' scrolled' : ' default'}
      >
        <div className="brand">
          <Link to="/">
            <img
              src={this.state.scrolled ? logoBlack : logoWhite}
              alt="Ekko App Logo: Click to go to frontpage"
            />
          </Link>
        </div>
        <nav className={this.state.menuOpen === true ? 'active' : ''}>
          <CloseIcon
            className="burger-menu-close-toggle"
            onClick={() => {
              this.setState({ menuOpen: false })
            }}
          />
          <Link
            activeClassName="active"
            onClick={() => this.setState({ menuOpen: false })}
            to="/om-os"
          >
            Om os
          </Link>
          <Link
            activeClassName="active"
            onClick={() => this.setState({ menuOpen: false })}
            to="/ledige-stillinger"
          >
            Ledige stillinger
          </Link>
          <Link
            activeClassName="active"
            onClick={() => this.setState({ menuOpen: false })}
            to="/FAQ"
          >
            FAQ
          </Link>
          <Link
            activeClassName="active"
            onClick={() => this.setState({ menuOpen: false })}
            to="/kontakt"
          >
            Kontakt
          </Link>
          <Button
            type={
              this.state.scrolled
                ? 'fill dark header-action'
                : 'outline light header-action'
            }
            size="small"
            text="Gratis demo"
            onClick={() => {
              this.setState({ modalActive: true })
            }}
          />
        </nav>
        {this.state.menuOpen === false && (
          <MenuIcon
            className="burger-menu-open-toggle"
            onClick={() => {
              this.setState({ menuOpen: true })
            }}
          />
        )}
        {
          <FreeDemoModal
            active={this.state.modalActive}
            deactivate={() => {
              this.setState({ modalActive: false })
            }}
          />
        }
      </div>
    )
  }
}

function header() {
  return css`
    /**************** DESKTOP **********************/
    @media screen and (min-width: ${common.breakpointMedium + 1}px) {
      nav {
        display: block;
      }
      a {
        display: inline-block;
        ${typography.regular};
        text-decoration: none;
        line-height: ${common.headerHeight - 7}px;
      }

      /* Hides mobile menu toggles */
      .burger-menu-open-toggle,
      .burger-menu-close-toggle {
        display: none;
      }

      /* Unscrolled */
      &.default {
        a {
          color: ${colors.midGrey};
          border-top: 3px ${colors.white} transparent;

          &.active {
            border-top: 3px ${colors.white} solid;
            color: ${colors.white};
          }

          &:hover {
            color: ${colors.white};
          }
        }
      }

      /* Scrolled */
      &.scrolled {
        a {
          color: ${colors.black};
          border-top: 3px ${colors.white} transparent;

          &.active {
            border-top: 3px ${colors.seaGreen} solid;
            color: ${colors.seaGreen};
          }

          &:hover {
            color: ${colors.seaGreen};
          }
        }
      }
    }

    /**************** MOBILE **********************/
    @media screen and (max-width: ${common.breakpointMedium}px) {
      img.mobile-menu-logo {
        width: 120px;
        height: 52px;
        top: 0;
        position: absolute;
        left: 0.75rem;
      }

      @keyframes menuIn {
        0% {
          opacity: 0;
          transform: scale(1.1);
        }
        1% {
          opacity: 0;
          transform: scale(1.1);
        }
        99% {
          transform: scale(1);
        }
        100% {
          opacity: 1;
          transform: translateY(0%);
        }
      }

      nav.active {
        animation: menuIn 200ms ease forwards;
        transform: translateY(0%);
        opacity: 1;
      }

      nav {
        margin-top: -1px;
        transform: translateY(-100%);
        transition: opacity 200ms ease;
        opacity: 0;
        display: flex;
        position: fixed;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          -30deg,
          hsla(189, 52%, 10%, 0.9),
          hsla(190, 53%, 23%, 0.9)
        );

        a {
          display: block;
          width: 100%;
          color: ${colors.midGrey};
          font-size: 1.45rem;
          text-align: center;

          &.active {
            color: ${colors.white};
            border-left: 5px ${colors.white} solid;
            background-color: hsla(0, 0%, 100%, 0.2);
          }
        }

        button.header-action {
          margin-top: 2rem;
          line-height: 50px;
          font-size: 1.15rem;
          padding: 0 2.65rem;
          background-color: ${colors.seaGreen} !important;
          border: 1px ${colors.seaGreen} solid !important;
          color: ${colors.white};

          &:hover {
            background-color: ${color(colors.seaGreen)
              .lighten(0.2)
              .hex()};
            border: 1px solid
              ${color(colors.seaGreen)
                .lighten(0.2)
                .hex()};
          }

          &:active {
            background-color: ${color(colors.seaGreen)
              .darken(0.1)
              .hex()};
            border: 1px
              ${color(colors.seaGreen)
                .darken(0.1)
                .hex()}
              solid;
          }
        }
      }
    }

    /* Unscrolled */
    &.default {
      transition: background-color 120ms ease;
      background-color: transparent;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);

      .burger-menu-open-toggle {
        fill: ${colors.white};
        background-color: transparent;
        border: 1px ${colors.whiteTransparent} solid;
        transition: fill 200ms ease, background-color 200ms ease;
      }
    }

    /* Scrolled */
    &.scrolled {
      transition: background-color 120ms ease;
      background-color: ${colors.white};
      border-bottom: 1px solid ${colors.midGrey};

      .burger-menu-open-toggle {
        fill: ${colors.white};
        background-color: ${colors.seaGreen};
        border: 1px ${colors.seaGreen} solid;
        transition: fill 200ms ease, background-color 200ms ease;

        &:hover {
          background-color: ${color(colors.seaGreen)
            .lighten(0.2)
            .hex()};
        }
        &:active {
          background-color: ${color(colors.seaGreen)
            .darken(0.1)
            .hex()};
        }
      }
    }

    /* Universal toggle-styling */
    .burger-menu-open-toggle,
    .burger-menu-close-toggle {
      padding: 0.43rem;
      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;
      margin-top: 0.3rem;
      box-shadow: ${common.boxShadow};
      filter: drop-shadow(0px 2px 3px $000);
      cursor: pointer;
    }
    .burger-menu-close-toggle {
      fill: ${colors.white};
      background-color: transparent;
      border: 1px ${colors.whiteTransparent} solid;
      transition: fill 200ms ease, background-color 200ms ease;
      position: absolute;
      right: 0.75rem;
      top: 0;
    }

    /**************** UNIVERSAL **********************/
    ${typography.regular};
    z-index: 1;
    position: fixed;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: ${common.headerHeight}px;
    line-height: ${common.headerHeight}px;
    box-shadow: ${common.boxShadow};
    padding: 0 0.75rem;

    .brand img {
      height: ${common.headerHeight}px;
      width: 120px;
    }

    nav a {
      text-decoration: none;
      padding: 0 0.45rem;
      margin: 0 0.25rem;
      transition: color 80ms ease, border 160ms ease;
      &.active {
        transition: color 80ms ease, border 160ms ease;
      }
    }
    nav button {
      margin-left: 0.75rem;
    }
  `
}

export default Header
