import React from 'react'
// import { css } from 'emotion'
import { css } from '@emotion/core'
import color from 'color'

// Styles
import colors from '../styles/colors'
import typography from '../styles/typography'
import common from '../styles/common'

const Button = props => {
  if (props.useAnchorTag === true) {
    return (
      <a
        className={` ${props.size || ''} ${props.type || ''}  ${
          props.image ? 'with-image' : ''
        }  button`}
        css={style}
        href={props.href}
        rel={props.rel}
        target={props.target}
        onClick={props.onClick}
      >
        {props.image && <img className="link-image" src={props.image} alt="" />}
        {props.text}
      </a>
    )
  } else {
    return (
      <button
        type={props.nativeType}
        css={style}
        className={`${props.size || ''} ${props.type || ''} ${
          props.image ? 'with-image' : ''
        }  `}
        onClick={props.onClick}
      >
        {props.image && <img className="link-image" src={props.image} alt="" />}
        {props.text}
      </button>
    )
  }
}

const LINE_HEIGHT = 45

function style() {
  return css`
    ${typography.button};
    display: inline-block;
    line-height: ${LINE_HEIGHT}px;
    padding: 0rem 2.5rem;
    text-align: center;
    border-radius: 30px;
    outline: 0px;
    cursor: pointer;
    box-shadow: ${common.boxShadow};
    border: 0px;
    transition: all 200ms ease;
    text-decoration: none;
    position: relative;

    &.with-image {
      padding-left: 3.5rem;
    }

    .link-image {
      position: absolute;
      width: ${LINE_HEIGHT - 6}px;
      height: ${LINE_HEIGHT - 6}px;
      border-radius: 50%;
      left: 3px;
      top: 3px;
      border: 1px rgba(0, 0, 0, 0.1) solid;
    }

    &.small {
      font-size: 0.8 5rem;
      line-height: 30px;
      padding: 0rem 1.5rem;
    }

    &.large {
      line-height: 50px;
      font-size: 1.15rem;
      padding: 0 2.65rem;
    }

    &.full-width {
      width: 100%;
      display: block;
    }

    &.dark.fill {
      background-color: ${colors.seaGreen};
      border: 1px ${colors.seaGreen} solid;
      color: ${colors.white};

      &:hover,
      &:focus {
        background-color: ${color(colors.seaGreen)
          .lighten(0.2)
          .hex()};
        border: 1px ${colors.seaGreen} solid;
      }

      &:active {
        background-color: ${color(colors.seaGreen)
          .darken(0.1)
          .hex()};
        border: 1px
          ${color(colors.seaGreen)
            .darken(0.1)
            .hex()}
          solid;
      }
    }
    &.dark.outline {
      border: 1px
        ${color(colors.deepSeaBlue)
          .desaturate(0.1)
          .lighten(0.3)
          .hex()}
        solid;
      background-color: ${colors.white};
      color: ${colors.seaGreen};

      &:hover,
      &:focus {
        border: 1px solid
          ${color(colors.deepSeaBlue)
            .desaturate(0.4)
            .lighten(2)
            .hex()} !important;
      }

      &:active {
        color: ${color(colors.seaGreen)
          .darken(0.1)
          .hex()};
      }
    }
    &.light.fill {
      background-color: ${colors.white};
      color: ${colors.deepSeaBlue};
      border: 1px ${colors.white} solid;

      &:hover,
      &:focus {
        background-color: rgba(255, 255, 255, 0.85);
      }
    }
    &.light.outline {
      border: 1px ${colors.whiteTransparent} solid;
      background-color: rgba(0, 0, 0, 0);
      color: ${colors.white};

      &:hover,
      &:focus {
        border: 1px rgba(255, 255, 255, 0.2) solid;
      }
    }
  `
}

export default Button
