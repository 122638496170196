let fontStack = "font-family: 'Fira Sans', sans-serif;"
let lineHeight = "line-height: 1.5;"

export default {
  mainHeading: `
    ${fontStack} 
    lineHeight: 1.1; 
    font-size: 3rem;
    font-weight: 700`,
  secondaryHeading: `
    ${fontStack} 
    lineHeight: 1.1; 
    font-size: 1.815rem;
    font-weight: 700`,
  tertiaryHeading: `
    ${fontStack} 
    lineHeight: 1.1; 
    font-size: 1.5rem;
    font-weight: 400`,
  lead: `
    ${fontStack} 
    ${lineHeight} 
    font-size: 1.2rem;
    font-weight: 400`,
  regular: `
    ${fontStack} 
    ${lineHeight} 
    font-size: 1rem;
    font-weight: 400`,
  meta: `
    ${fontStack} 
    ${lineHeight} 
    font-size: 0.8125rem;
    font-weight: 400`,
  button: `
    ${fontStack}
    font-weight: 500;
  `,
}