import React from 'react'
// import { css } from 'emotion'
import { css } from '@emotion/core'
import Link from 'gatsby-link'
import colors from '../styles/colors'
import common from '../styles/common'
import logoWhite from '../images/ekko-app_company-logo_white.svg'

const Button = () => (
  <div css={componentStyle} className={'container full-width'}>
    <div className="inner-container">
      <img src={logoWhite} alt="Ekko App logo" />

      <div className="col col-33">
        <p className="title">Ekko App</p>
        <Link to="/FAQ">FAQ</Link>
        <Link to="/#moduler">Moduler</Link>
        <Link to="/#features">Features</Link>
      </div>
      <div className="col col-33">
        <p className="title">Om os</p>
        <Link to="/om-os">Om ToEcho</Link>
        <Link to="/ledige-stillinger">Ledige stillinger</Link>
      </div>
      <div className="col col-33">
        <p className="title">Kontakt</p>
        <a href="mailto:kontakt@toecho.dk">kontakt@toecho.dk</a>
        <a href="tel:71747210">71 74 72 10</a>
        <a
          href="https://linkedin.com/company/ekkoapp/"
          rel="noreferrer"
          target="_blank"
        >
          LinkedIn
        </a>
        <Link to="/kontakt">Kontaktformular</Link>
      </div>

      {/* <div className="col col-33">
        <p className="title">Navigation</p>
        <Link to="/kontakt">Kontakt</Link>
      </div>
      <div className="col col-33">
        <p className="title">Kontakt</p>
      </div>
      <div className="col col-33">
        <p className="title">ToEcho ApS</p>
      </div> */}

      <p className="title u_mt-lg text-center">ToEcho ApS</p>

      <a
        rel="noopener"
        style={{ textAlign: 'center' }}
        href="https://goo.gl/maps/TY7Ktw2h7P22"
      >
        The Camp, Klausdalsbrovej 601, 2750 Ballerup
      </a>
      <p className="meta text-center">© 2020</p>
    </div>

    <div className="background-fill" />
  </div>
)

function componentStyle() {
  return css`
    background-color: ${colors.black};
    padding: 0px 0 50px 0;
    position: relative;
    z-index: 0;

    img {
      max-width: 180px;
      display: block;
      margin: 0 auto 40px auto;
    }

    p.title {
      color: ${colors.white};
      margin-bottom: 0.75rem;
      opacity: 1;
    }

    p,
    a {
      text-decoration: none;
      display: block;
      color: ${colors.midGrey};
      opacity: 0.7;
      margin-bottom: 0.25rem;
      line-height: 1.5;
    }
    a > p {
      opacity: 1;
    }
    a:hover {
      opacity: 1;
    }

    .col {
      vertical-align: top;
      padding-left: 3rem;
    }

    div.inner-container {
      max-width: 700px !important;
      margin: 0 auto;
    }

    /* Background */
    div.background-fill {
      z-index: -1;
      position: absolute;
      top: calc(-35px + -1vw);
      left: 0;
      width: 100%;
      height: 100%;
      transform: skewY(-2.25deg);
      background-color: ${colors.black};
    }

    @media screen and (max-width: ${common.breakpointMedium - 100}px) {
      div.col.col-33 {
        width: 100% !important;
        display: block;
        text-align: center;
        margin-bottom: 2rem;
        padding: 0;
      }
    }
  `
}

export default Button
