import React from 'react'
import PropTypes from 'prop-types'

// Components
import Header from '../components/header'
import Footer from '../components/footer'
import Helmet from 'react-helmet'

// Styles
import './index.css'
import { css } from '@emotion/core'
import common from '../styles/common'
import colors from '../styles/colors'
import typography from '../styles/typography'

// Images
import favicon57 from '../images/favicons/apple-icon-57x57.png'
import favicon60 from '../images/favicons/apple-icon-60x60.png'
import favicon72 from '../images/favicons/apple-icon-72x72.png'
import favicon76 from '../images/favicons/apple-icon-76x76.png'
import favicon14 from '../images/favicons/apple-icon-114x114.png'
import favicon20 from '../images/favicons/apple-icon-120x120.png'
import favicon44 from '../images/favicons/apple-icon-144x144.png'
import favicon52 from '../images/favicons/apple-icon-152x152.png'
import favicon80 from '../images/favicons/apple-icon-180x180.png'
import favicon92 from '../images/favicons/android-icon-192x192.png'
import favicon32 from '../images/favicons/favicon-32x32.png'
import favicon96 from '../images/favicons/favicon-96x96.png'
import favicon16 from '../images/favicons/favicon-16x16.png'

const Layout = ({ children, data }) => (
  <div css={baseStyles}>
    <Helmet>
      <link rel="apple-touch-icon" sizes="57x57" href={favicon57} />
      <link rel="apple-touch-icon" sizes="60x60" href={favicon60} />
      <link rel="apple-touch-icon" sizes="72x72" href={favicon72} />
      <link rel="apple-touch-icon" sizes="76x76" href={favicon76} />
      <link rel="apple-touch-icon" sizes="114x114" href={favicon14} />
      <link rel="apple-touch-icon" sizes="120x120" href={favicon20} />
      <link rel="apple-touch-icon" sizes="144x144" href={favicon44} />
      <link rel="apple-touch-icon" sizes="152x152" href={favicon52} />
      <link rel="apple-touch-icon" sizes="180x180" href={favicon80} />
      <link rel="icon" type="image/png" sizes="192x192" href={favicon92} />
      <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
      <link rel="icon" type="image/png" sizes="96x96" href={favicon96} />
      <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="description"
        content="Styrk fællesskabet med effektiv kommunikation - Motivér, uddan, del viden og succeshistorier med en App, hvor funktionalitet og en intuitiv brugerflade går hånd i hånd"
      />
      <meta
        name="keywords"
        content="Ekko App, ekko app, To Echo, to echo, Ekko Updater, ekko updater, intranet, mobilt intranet"
      />
      <meta name="robots" content="all" />
    </Helmet>
    <Header />
    {children}
    <Footer />
  </div>
)

function baseStyles() {
  return css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

    .container {
      white-space: nowrap;
      width: 92%;
      max-width: 960px;
      margin: 0 auto;

      * {
        white-space: normal !important;
      }

      &.full-width {
        width: 100%;
        max-width: 100%;

        .inner-container {
          width: 92%;
          max-width: 960px;
          margin: 0 auto;
        }
      }

      .col {
        white-space: initial;
        display: inline-block;
      }
      .col.col-55 {
        width: 55%;
      }
      .col.col-50 {
        width: 50%;
      }
      .col.col-45 {
        width: 45%;
      }
      .col.col-33 {
        width: 33%;
      }
      .col.col-25 {
        width: 25%;
      }
    }

    /* Typography  */
    h1 {
      ${typography.mainHeading};
      color: ${colors.deepSeaBlue};
    }
    h3 {
      ${typography.tertiaryHeading};
      color: ${colors.deepSeaBlue};
    }

    @media screen and (max-width: ${common.breakpointMedium}px) {
      h1 {
        font-size: 2rem;
      }
      h2 {
        font-size: 1.65rem;
      }
      h3 {
        font-size: 1.3rem;
      }
    }

    p.lead {
      ${typography.lead};
      color: ${colors.deepSeaBlue};
      margin-bottom: 1rem;
    }
    p {
      ${typography.regular};
      color: ${colors.deepSeaBlue};
      margin-bottom: 1rem;
    }
    input,
    label,
    textarea {
      display: block;
      margin: 0;
      padding: 0;
    }

    input,
    textarea {
      border: 0px transparent none;
      background-color: ${colors.lightGrey};
      padding: 0.5rem;
      border-radius: 3px;
      width: 100%;
      color: ${colors.black};
    }

    .background-fill {
      z-index: -1;
      position: absolute;
      top: calc(-35px + -1vw);
      left: 0;
      width: 100%;
      height: 100%;
      transform: skewY(-2.25deg);
    }
  `
}

Layout.propTypes = {
  children: PropTypes.func,
}

export default Layout

export const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
