export default {
  seaGreen: "#0A7C7C",
  seaBlue: "#17798B",
  splashOfBlue: "#189DBB",
  deepSeaBlue: "#1C505A",
  red: "#dc0755",
  white: "#FFFFFF",
  whiteTransparent: "rgba(255,255,255,0.5)",
  ultraLightGrey: "#FAFAFA",
  lightGrey: "#F2F4F5",
  midGrey: "#D2D5D6",
  black: "#0C2226",
  blackTransparent: "#0C2226",
  boxShadow: "rgba(0,0,0,0.05)"
}