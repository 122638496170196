import React from 'react'
import { Motion, spring } from 'react-motion'

// Components
import Button from './button'

// Style
// import { css } from 'emotion'
import { css } from '@emotion/core'
import colors from '../styles/colors'
import typography from '../styles/typography'
import common from '../styles/common'

// Icons
import { CloseIcon, AlertDecagramIcon, DecagramCheckIcon } from 'mdi-react'

class FreeDemoModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      email: '',
      phone: '',
      company: '',

      sendingMessage: false,
      messageSent: false,
      messageFailedToSend: false,

      validationError: false,
      validationErrorMessage: '',
    }
  }

  updateName = event => {
    this.setState({ name: event.target.value })
  }

  updatePhone = event => {
    this.setState({ phone: event.target.value })
  }

  updateEmail = event => {
    this.setState({ email: event.target.value })
  }

  updateCompany = event => {
    this.setState({ company: event.target.value })
  }

  formSubmit = event => {
    event.preventDefault()
    let validationResult = this.validateForm(this.state.name, this.state.email)

    // Check if form is valid
    if (validationResult.error) {
      this.setState({
        validationError: true,
        validationErrorMessage: validationResult.message,
      })
      return
    }

    // Set state
    this.setState({ sendingMessage: true })

    // Make request
    let request = new XMLHttpRequest()
    request.addEventListener('load', this.handleResponseOk)
    request.addEventListener('error', this.handleResponseError)
    // request.onreadystatechange = e => {
    //   if (request.readyState === 4) {
    //     this.handleResponse(e)
    //   }
    // }
    request.open(
      'GET',
      `/mail-service.asp?type=free-demo&name=${this.state.name}&email=${
        this.state.email
      }&phone=${this.state.phone}&company=${this.state.company}`
    )
    request.send()
  }

  handleResponseOk = e => {
    if (Number(e.target.status) === 200) {
      this.setState({ sendingMessage: false, messageSent: true })
    } else {
      this.handleResponseError()
    }
  }
  handleResponseError = e => {
    this.setState({ sendingMessage: false, messageFailedToSend: true })
  }

  validateForm = (name, email) => {
    if (!name) {
      return { error: true, message: 'Du mangler at tilføje dit navn' }
    }
    if (!email) {
      return { error: true, message: 'Du mangler at tilføje din email' }
    }
    return { error: false }
  }

  getForm = () => {
    if (this.state.messageSent && !this.state.messageFailedToSend) {
      return (
        <div className="message-sent">
          <DecagramCheckIcon />
          <h3>Din forsespørgsel er afsendt.</h3>
          <p className="lead">
            Tak for din interesse vedrørende en gratis demo af Ekko App. Vi har
            sendt en kopi til din mail og kontakter dig hurtigst muligt.
          </p>
        </div>
      )
    } else if (this.state.messageFailedToSend) {
      return (
        <div className="message-sent">
          <AlertDecagramIcon />
          <h3> Kunne ikke sende din forespørgsel</h3>
          <p className="lead">
            Der er sket en fejl med afsendelsen af din forespørgsel. Skriv til
            os på <a href="mailto:kontakt@toecho.dk">kontakt@toecho.dk</a> i
            stedet. Vi beklager ulejligheden.
          </p>
        </div>
      )
    } else {
      return (
        <form onSubmit={this.formSubmit}>
          {this.state.validationError && (
            <div className="error-message u_mb-sm">
              <AlertDecagramIcon className="u_mr-xs" />
              <p>{this.state.validationErrorMessage}</p>
            </div>
          )}

          <div className="col col-50 col-left">
            <label htmlFor="txtName">
              Navn
              <input
                id="txtName"
                type="text"
                placeholder="Indtast dit navn"
                value={this.state.name}
                onChange={this.updateName}
              />
            </label>

            <label htmlFor="txtPhone">
              Telefon (valgfri)
              <input
                id="txtPhone"
                type="text"
                placeholder="Indtast dit telefonnummer"
                value={this.state.phone}
                onChange={this.updatePhone}
              />
            </label>
          </div>
          <div className="col col-50 col-right">
            <label htmlFor="txtEmail">
              Email
              <input
                id="txtEmail"
                type="text"
                placeholder="Indtast din email"
                value={this.state.email}
                onChange={this.updateEmail}
              />
            </label>

            <label htmlFor="txtCompany">
              Virksomhed (valgfri)
              <input
                id="txtCompany"
                type="text"
                placeholder="Indtast navnet på din virksomhed"
                value={this.state.company}
                onChange={this.updateCompany}
              />
            </label>
          </div>
          <Button
            type="fill dark full-width u_mt-md"
            text="Bestil gratis demo"
            nativeType="submit"
          />
        </form>
      )
    }
  }

  render() {
    return this.props.active ? (
      <Motion
        defaultStyle={{ y: 80, opacity: 0 }}
        style={{
          y: spring(0, { stiffness: 600, damping: 20 }),
          opacity: spring(1),
        }}
      >
        {style => (
          <div
            css={componentStyle}
            style={{
              opacity: style.opacity,
            }}
          >
            <div
              className="modal container"
              style={{
                transform: `translateY(${style.y}px)`,
                opacity: style.opacity,
              }}
            >
              <CloseIcon
                className="close-icon"
                onClick={this.props.deactivate}
              />

              <h1 className="u_mb-sm">Prøv en gratis demo</h1>
              <p className="u_mb-md">
                Udfyld formularen nedenfor, så kontakter vi dig hurtigst muligt
              </p>
              {this.getForm()}
            </div>
          </div>
        )}
      </Motion>
    ) : null
  }
}

function componentStyle() {
  return css`
    &.hidden {
      /* display: none; */
    }

    z-index: 1;

    /* animation: freeDemoModalIn 120ms ease forwards; */
    position: fixed;
    top: 0;
    left: 0;
    background: linear-gradient(
      -30deg,
      hsla(189, 52%, 10%, 0.9),
      hsla(190, 53%, 23%, 0.9)
    );
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal {
      background-color: ${colors.white};
      padding: 1rem;
      position: relative;
      border-radius: 3px;
      box-shadow: 0 3px 6px ${colors.black};
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      max-height: 96vh;
      margin: 0;
    }

    h1,
    p {
      max-width: calc(100% - 70px);
    }

    input,
    label,
    textarea {
      line-height: 1;
      &:focus {
        outline: 1px ${colors.seaBlue} solid;
      }
    }

    label {
    }

    input {
      margin-top: 0.5rem;
      margin-bottom: 2rem;
    }

    a {
      text-decoration: none;
      border-bottom: 1px ${colors.seaGreen} dashed;
      color: ${colors.seaGreen};
    }

    .error-message {
      display: flex;

      svg,
      p {
        display: inline-block;
        color: ${colors.red};
        fill: ${colors.red};
      }
    }

    .message-sent {
      text-align: center;
      padding: 50px 0 50px 0;

      a {
        ${typography.lead};
        color: ${colors.seaGreen};
      }

      p {
        margin: 0 auto;
        max-width: 500px;
      }
      svg {
        width: 3rem;
        height: 3rem;
        fill: ${colors.seaGreen};
      }
    }

    .col {
      vertical-align: top;

      &.col-left {
        padding-right: 1%;
      }

      &.col-right {
        padding-left: 1%;
      }
    }

    @media screen and (max-width: ${common.breakpointMedium}px) {
      .container .col.col-50 {
        width: 100%;
        display: block;
        margin: 0;
        padding: 0;
      }
    }

    svg.close-icon {
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 50px;
      height: 50px;
      border: 1px ${colors.midGrey} solid;
      border-radius: 50%;
      padding: 8px;
      fill: ${colors.seaGreen};
      cursor: pointer;
      &:hover {
        border-color: ${colors.seaGreen};
      }
    }

    @keyframes freeDemoModalIn {
      0% {
        opacity: 0;
        transform: scale(1.2);
      }
      1% {
        opacity: 0;
        transform: scale(1.2);
      }
      100% {
        opacity: 1;
        transform: scale(1);
      }
    }
  `
}

export default FreeDemoModal
